//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { handleTaskTurn } from '@/api/modular/flowable/handleTaskManage'
import { sysUserSelector } from '@/api/modular/system/userManage'
import { sysRoleDropDown } from '@/api/modular/system/roleManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      spinningLoading: false,
      recordData: {},
      userList: [],
      roleList: [],
      userChangList: [],
      roleChangList: [],
      form: this.$form.createForm(this)
    }
  },
  methods: {
    /**
     * 初始化方法
     */
    open (record) {
      this.recordData = record
      this.visible = true
      this.getUserRoleList()
    },
    getUserRoleList () {
      sysUserSelector().then((res) => {
        this.userList = res.data
      })
      sysRoleDropDown().then((res) => {
        this.roleList = res.data
      })
    },
    /**
     * 提交
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((errors, values) => {
        if (!errors) {
          values.taskId = this.recordData.id
          values.assigneeList = []
          if (this.userChangList.length > 0) {
            this.userChangList.forEach(item => {
              values.assigneeList.push(item)
            })
          }
          if (this.roleChangList.length > 0) {
            this.roleChangList.forEach(item => {
              values.assigneeList.push(item)
            })
          }
          if (values.assigneeList.length < 1) {
            this.$message.warning('請選擇轉辦用戶或角色')
            return
          }
          this.confirmLoading = true
          handleTaskTurn(values).then((res) => {
            if (res.success) {
              this.$message.success('轉辦成功')
              this.returnPage()
            } else {
              this.$message.error('轉辦失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        }
      })
    },
    /**
     * 返回並清空界面緩存
     */
    returnPage () {
      this.visible = false
      this.form.resetFields()
      this.$emit('close')
    },
    /**
     * 關閉
     */
    handleCancel () {
      this.visible = false
    },

    handleChangeUser (value) {
      this.userChangList = value
    },
    handleChangeRole (value) {
      this.roleChangList = value
    }
  }
}
